import React from 'react';
import AppContext from './AppContext';
import LoginForm from './LoginForm';
import HomePage from './HomePage';
import LoadingPage from './LoadingPage';
import * as IDB from '../indexedDB';
import jwt_decode from 'jwt-decode';
import { auxiliarTables } from '../indexedDB/tables';


class ContextProvider extends React.Component {

    interval = null;

    state = {
        contentRef: React.createRef(),
        endpoint: process.env.REACT_APP_ENDPOINT_URL ?? "http://localhost:5000/api/v1",
        alive: false,
        fetching: false,
        syncing: false,
        requestPending: false,
        modal: null,
        showModal: false,
        usuario: null,
        auxiliar: {},
        toasts: [],
        menu: false,
        content: <LoadingPage />,
        scrollToTop: () => this.state.contentRef.current.scroll(0,0),
        openModal: modal => this.setState({ modal, showModal: true }),
        closeModal: () => this.setState({ modal: null, showModal: false }),
        addToast: toast => this.setState(state => ({ toasts: [...state.toasts, toast] })),
        hideToast: toast => this.setState(state => ({ toasts: state.toasts.map(t => t === toast ? { ...toast, show: false } : t) })),
        setContent: content => this.setState({ menu: false, content }),
        home: () => this.setState(state => ({
            content: state.usuario ? <HomePage /> : <LoginForm />,
            menu: false
        })),
        loadAuxiliar: async () => {
            const array = await Promise.all(auxiliarTables.map(async storeName => ({ [storeName]: await IDB.getAll(storeName) })));
            const auxiliar = array.reduce((result, item) => ({ ...result, ...item }), {});
            this.setState({ auxiliar });
        },
        logon: async (cpf, senha) => {
            try {
                const { accessToken, refreshToken, usuario } = await this.state.request("POST", "/session", { cpf, senha });
                await IDB.put("Token", { id: 1, accessToken, refreshToken, usuario });
                this.setState({ usuario }, async () => {
                    await this.state.home();
                    await this.state.sync();
                });
            } catch (error) {
                console.log(error);
                this.state.addToast({
                    header: "Falha de Autenticação",
                    body: error.message
                });
            }
        },
        /*
        recover: async (email, callback) => {
            try {
                await this.state.request("POST", "/usuario/recuperar", { email });
                this.state.addToast({
                    header: "Recuperação de Senha",
                    body: "Se seus dados estiverem corretos, você receberá um email com o link para recuperar sua senha. Verifique sua caixa de entrada e spam."
                });
            } catch (error) {
                this.state.addToast({
                    header: "Erro",
                    body: error.message
                });
            } finally {
                callback();
            }
        },
        */
        logoff: async () => {
            try {
                if (window.confirm("Deseja sair? Não será possível utilizar o aplicativo offline.")) {
                    this.setState({ usuario: null }, async () => {
                        await IDB.clear("Token");
                        this.state.home();
                        this.state.addToast({
                            header: "Sair",
                            body: "Usuário desconectado com sucesso."
                        });
                    });
                }
            } catch (error) {
                this.state.addToast({
                    header: "Erro",
                    body: error.message
                });
            }
        },
        checkToken: () => new Promise(async (resolve, reject) => {
            try {
                const token = await IDB.get("Token", 1);
                if (token) {
                    const accessExp = jwt_decode(token.accessToken).exp;
                    const now = Date.now() / 1000;
                    if (accessExp < now) {
                        const refreshExp = jwt_decode(token.refreshToken).exp;
                        if (refreshExp < now) {
                            throw new Error("Token de atualização expirado.");
                        } else {
                            const refreshOptions = {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token.refreshToken}`
                                }
                            }
                            const response = await fetch(this.state.endpoint + '/session/refresh', refreshOptions);
                            const { accessToken, usuario } = await response.json();
                            const { refreshToken } = token;
                            await IDB.put("Token", { id: 1, accessToken, refreshToken, usuario })

                            this.setState({ accessToken, usuario }, () => resolve(true));
                        }
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            } catch (error) {
                reject(error);
            }
        }),
        request: (method, url, data = null, raw = false) => {
            // faz a solicitação HTTP para a API
            return new Promise((resolve, reject) => {
                const timeout = setTimeout(() => {
                    this.setState({ requestPending: true })
                }, process.env.REACT_APP_PENDING_TIMEOUT ?? 5000);

                const send = async () => {
                    try {
                        const token = await IDB.get("Token", 1);
                        const requestOptions = {
                            method,
                            headers: {
                            }
                        };

                        if (!(data instanceof FormData) && data)
                            requestOptions.headers['Content-Type'] = 'application/json';

                        if (token?.accessToken)
                            requestOptions.headers['Authorization'] = `Bearer ${token.accessToken}`;

                        if (method !== "GET" && data)
                            requestOptions.body = data instanceof FormData ? data : JSON.stringify(data)

                        const response = await fetch(this.state.endpoint + url, requestOptions);

                        if (response.ok) {
                            try {
                                if (raw) {
                                    resolve(response);
                                } else {
                                    const data = await response.json();
                                    resolve(data);
                                }
                            } catch (error) {
                                resolve();
                            }
                        } else {
                            const message = await response.text();
                            reject(new Error(message));
                        }

                    } catch (error) {
                        console.log(error);
                        this.state.addToast({ header: "Erro", body: error.message });
                    } finally {
                        this.setState({
                            fetching: false,
                            requestPending: false,
                        }, () => clearTimeout(timeout))
                    }
                }
                this.setState({ fetching: true }, async () => {
                    this.state.checkToken().then(send)
                });
            });
        },
        sync: () => new Promise((resolve, reject) =>
            this.setState({ syncing: true }, async () => {
                try {
                    const auxiliarData = await this.state.request("GET", "/auxiliar");
                    try {
                        await Promise.all(Object.keys(auxiliarData).map(async storeName => {
                            await IDB.clear(storeName);
                            await IDB.putAll(storeName, auxiliarData[storeName]);
                        }));
                    } catch (error) {
                        console.log(error);
                    }
                    await this.state.loadAuxiliar();
                    this.state.addToast({ header: "Sincronização", body: "Tabelas sincronizadas com sucesso." });
                    this.setState({ auxiliar: auxiliarData }, resolve);
                } catch (error) {
                    reject(error);
                } finally {
                    this.setState({ syncing: false });
                }
            })
        ),
        checkServer: () => fetch(this.state.endpoint + "/alive")
            .then(response => this.setState({ alive: response.ok }))
            .catch(() => this.setState({ alive: false })),
        hideMenu: () => this.setState({ menu: false }),
        showMenu: () => this.setState({ menu: true })
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidMount() {

        IDB.get("Token", 1)
            .then((tokens) => this.setState({ usuario: tokens?.usuario }, () => {
                this.interval = setInterval(() => this.state.checkServer(), process.env.REACT_APP_ALIVE_INTERVAL ?? 20000);
                this.state.checkServer();
                this.state.home();
                this.state.loadAuxiliar();
            }))
            .catch(error => {
                this.state.addToast({header: "Error", body: "Erro na recuperação do Token: " + error.message});
            });
    }


    render() {
        return <AppContext.Provider value={this.state}>
            {this.props.children}
        </AppContext.Provider>
    }
}

export default ContextProvider;